$iphone: 62em;
$SpotifyBlack: #000000;
$SpotifyWhite: #FFFFFF;
// $SpotifyGreen: #1DB954;
$SpotifyGreen: #1ED760;
$DarkGray: #121212;
$MediumGray: #202020;
$LightGray: #606060;



@font-face {
    font-family: Gotham;
    src: url(./fonts/GothamMedium.otf);
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

html {
    background-color: $SpotifyBlack;
}

.headerShort {
    @media only screen and (max-width: $iphone) {
        height: 100vh;
    }
}

.headerLong {
    height: 190vh;
    @media only screen and (max-width: $iphone) {
        height: 190vh;
    }
}

.headerMedium {
    height: 100vw;
    @media only screen and (max-width: $iphone) {
        height: 175vh;
    }
}
  
.header {
    background-color: $SpotifyBlack;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
    & * {
        font-family: Gotham;
    }
    
    @media only screen and (min-width: $iphone) {
        height: 100vh;
    }

    .interspotLogo {
        width: 10vw;
        @media only screen and (max-width: $iphone) {
            width: 50vw;
        }
    }

    .welcomeContainer {
        width: 85vw;
        height: 75vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        @media only screen and (max-width: $iphone) {
            height: 145vh;
        }

        .about {
            color: $SpotifyWhite;
            height: 20%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
            width: 35%;
            font-size: 1.5vw;
            @media only screen and (max-width: $iphone) {
                width: 90%;
                font-size:6.5vw;
                height: 15%;
            }
        }

        .steps {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            height: 50%;
            @media only screen and (max-width: $iphone) {
                flex-direction: column;
                height: 75%;
                align-items: center;
                justify-content: space-between;
            }



            .result {
                width: 10vw;
            }
            .step {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                @media only screen and (max-width: $iphone) {
                    flex-direction: column;
                    height: 28%;
                }

                .number {
                    width: 1.5vw;
                    @media only screen and (max-width: $iphone) {
                        // width: 8vw;
                        display: none;
                    }
                }
                .screenshotWrapper {
                    .screenshot {
                        height: 10vw;
                        @media only screen and (max-width: $iphone) {
                            height: 20vh;
                            max-width: 80vw;
                        }
                    }
                }
                .stepInfo {
                    color: $LightGray;
                    height: 10%;
                    text-align: center;
                    font-size: 1.2vw;
                    width: 15vw;
                    @media only screen and (max-width: $iphone) {
                        font-size: 6vw;
                        width: 70vw;
                    }
                }
            }
        }
    }

    .containerHorizontal {
        align-items: center;
        justify-content: space-evenly;
    }

    .containerVertical {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .containerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 155vh;
    }
  
    .container {
        background-color: $DarkGray;
        // background-color: black;
        width: 85vw;
        height: 75vh;
        border-radius: 2vw;
        display: flex;
        @media only screen and (max-width: $iphone) {
            justify-content: center;
            align-items: center;
            border-radius: 7vw;
        }

        .verticalLine {
            border-left: 1px solid $MediumGray;
            height: 95%
        }

        .playlistSelection {
            height: 90%;
            @media only screen and (max-width: $iphone) {
                height: 90%;
            }
        }
        .signIn {
            height: 85%;
            @media only screen and (max-width: $iphone) {
                height: 90%;
            }
        }

        .playlistSelection, .signIn {
            color: $SpotifyWhite;
            width: 32%;
            // height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: $iphone) {
                width: 90%;
                // height: 90%;
            }

            .name {
                background-color: $MediumGray;
                border-radius: 10vw;
                width: 30%;
                height: 6%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media only screen and (max-width: $iphone) {
                    width: 60%;
                    height: 8%;
                }

                input {
                    background-color: $MediumGray;
                    max-width: 80%;
                    width: fit-content;
                    resize: none;
                    border: none;
                    color: $LightGray;
                    font-size: 1.1vw;
                    text-align: center;
                    @media only screen and (max-width: $iphone) {
                        font-size: 5vw;
                    }

                    &::placeholder {
                        color: $LightGray;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }

            .profilePicture {
                position: relative;
                width: 50%;
                border-radius: 100vw;
                border: none;
                background-color: $SpotifyBlack;
                @media only screen and (max-width: $iphone) {
                    width: 60%;
                }
            }

            .guide {
                color: $LightGray;
                font-size: 1.5vw;
                text-align: center;
                width: 70%;
                @media only screen and (max-width: $iphone) {
                    font-size: 6vw;
                    width: 80%;
                }
            }

            .spotifyLogo {
                width: 25%;
                @media only screen and (max-width: $iphone) {
                    width: 30%;
                }
            }

            .playlistsContainer {
                background-color: $MediumGray;
                width: 100%;
                height: 80%;
                border-radius: 2vw;
                padding: 5%;
                display: flex;
                justify-content: center;
                @media only screen and (max-width: $iphone) {
                    border-radius: 4vw;
                    height: 77%;
                }
                
                .playlistsWrapper {
                    height: 100%;
                    width: 100%;
                    overflow: scroll;
                    display: grid;
                    grid-template-columns: 37% 37%;
                    gap: 10%;
                    justify-content: center;
                    @media only screen and (max-width: $iphone) {
                        max-width: 100%;
                        overflow-x: hidden;
                        grid-template-columns: 40% 40%;
                    }
                    overflow-x: hidden;
                    // .example::-webkit-scrollbar {
                    //     display: none;
                    // }

                    .playlistLink {
                        .playlistLinkIcon {
                            width: 0.8rem;
                            height: 0.8rem;
                            margin-left: 0.3rem;
                            fill: $LightGray;
                        }
                        color: $LightGray;
                    }

                    .playlist {
    
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        text-align: center;
                        
                        .playlistCover {
                            width: 100%;
                            margin-bottom: 10%;
                            border-radius: 10%;
                        }
                    }

                    .playlistSelected {
    
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        text-align: center;
                        background-color: $SpotifyGreen;
                        padding: 5%;
                        border-radius: 4%;
                        height: fit-content;

                        .playlistCover {
                            width: 100%;
                        }
                        .playlistName {
                            margin-top: 8%;
                            margin-bottom: 5%;
                        }
                        .playlistLink {
                            color: $SpotifyWhite;
                        }
                    }
                }

            }

            .addPlaylist, .linkProfile {
                width: 100%;
                background-color: $MediumGray;
                height: 6%;
                border-radius: 2vw;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 3%;

                input {
                    background-color: $MediumGray;
                    width: 72%;
                    resize: none;
                    border: none;
                    color: $LightGray;
                    font-size: 1vw;
                    &::placeholder {
                        color: $LightGray;
                    }
                    &:focus {
                        outline: none;
                    }
                }

                img {
                    height: 50%;
                    fill: $LightGray;
                }

                button {
                    background-color: $SpotifyGreen;
                    color: $SpotifyWhite;
                    height: 100%;
                    border: none;
                    border-radius: 2vw;
                    width: 20%;
                    font-size: 1.1vw;
                    cursor: pointer;
                }
            }   
            button {
                background-color: $SpotifyGreen;
                color: $SpotifyWhite;
                height: 6%;
                border: none;
                border-radius: 10vw;
                padding-left: 3%;
                padding-right: 3%;
                font-size: 1.1vw;
                cursor: pointer;
                &:disabled {
                    background-color: $LightGray;
                    color: $MediumGray;
                    cursor: not-allowed;
                }
                @media only screen and (max-width: $iphone) {
                    width: 50%;
                    height: 8%;
                    font-size: 5vw;
                }
            }
        }

        .loading {
            color: $SpotifyWhite;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            justify-content: space-between;
            font-size: 1.1vw;
            height: 25%;
            @media only screen and (max-width: $iphone) {
                font-size: 5vw;
                height: 30%;
            }


            .loadingAnimation {
                width: 7vw;
                height: 7vw;
                position: relative;
                @media only screen and (max-width: $iphone) {
                    width: 25vw;
                    height: 25vw;
                }
            }
            
            .double-bounce1, .double-bounce2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: $LightGray;
                opacity: 0.6;
                position: absolute;
                top: 0;
                left: 0;
                
                -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
                animation: sk-bounce 2.0s infinite ease-in-out;
            }
            
            .double-bounce2 {
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s;
            }
            
            @-webkit-keyframes sk-bounce {
                0%, 100% { -webkit-transform: scale(0.0) }
                50% { -webkit-transform: scale(1.0) }
            }
            
            @keyframes sk-bounce {
                0%, 100% { 
                    transform: scale(0.0);
                    -webkit-transform: scale(0.0);
                } 50% { 
                    transform: scale(1.0);
                    -webkit-transform: scale(1.0);
                }
            }
        }

        .intersectionCard {
            background-color: $MediumGray;
            border-radius: 2vw;
            color: $SpotifyWhite;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 30%;
            height: 90%;
            @media only screen and (max-width: $iphone) {
                width: 90%;
                height: 94%;
                border-radius: 7vw;
            }

            .intersectionName {
                font-size: 1.5vw;
                @media only screen and (max-width: $iphone) {
                    font-size: 6vw;
                }
            }
            .intersectionCover{
                width: 80%;
                border-radius: 1vw;
                @media only screen and (max-width: $iphone) {
                    border-radius: 5vw;
                    width: 85%;
                }
            }
            .intersectionLink {
                width: 80%;
                background-color: $LightGray;
                height: 6%;
                border-radius: 10vw;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 3%;
                @media only screen and (max-width: $iphone) {
                    // width: 85%;
                    // justify-content: center;
                    display: none;
                }

                & a {
                    text-decoration: none;
                    font-size: 0.53vw;
                    color: $SpotifyWhite;
                    @media only screen and (max-width: $iphone) {
                        font-size: 1.8vw;
                    }
                }

                button {
                    background-color: $LightGray;
                    color: $SpotifyWhite;
                    height: 100%;
                    border: none;
                    border-radius: 2vw;
                    width: 15%;
                    font-size: 0.7vw;
                    cursor: pointer;
                    @media only screen and (max-width: $iphone) {
                        display: none;
                    }

                    .copyIcon {
                        height: 1.5vh;
                        aspect-ratio: 1;
                    }
                }
            }
            .openInSpotify {
                width: 80%;
                background-color: $SpotifyGreen;
                border-radius: 10vw;
                height: 7%;
                color: $SpotifyWhite;
                font-size: 1vw;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                @media only screen and (max-width: $iphone) {
                    font-size: 4vw;
                    height: 9%;
                }
            }
            .playListIsInYourLibrary {
                font-size: 0.8vw;
                @media only screen and (max-width: $iphone) {
                    font-size: 4vw;
                    width: 70%;
                }
            }
        }

        .error {
            color: $SpotifyWhite;
            font-size: 1vw;
            @media only screen and (max-width: $iphone) {
                width: 80%;
                font-size: 6vw;
                text-align: center;
            }
        }

    }

    .mainButton {
        @media only screen and (max-width: $iphone) {
            width: 55vw;
            height: 15vw;
            font-size: 7vw;
        }
        background-color: $SpotifyGreen;
        color: $SpotifyWhite;
        width: 12vw;
        height: 3vw;
        border-radius: 10vw;
        border: none;
        font-size: 1.1vw;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        &:disabled {
            background-color: $LightGray;
            color: $MediumGray;
            cursor: not-allowed;
        }
    }

    .startOnGitHub {
        @media only screen and (max-width: $iphone) {
            display: none;
        }
        position: absolute;
        right: 0.5vw;
        bottom: 0.5vw;
        color: $LightGray;
        text-decoration: none;
        font-size: 0.8vw;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        justify-items: center;
        background-color: $MediumGray;
        height: 2vw;
        width: 8.5vw;
        border-radius: 10vw;
        svg {
            fill: $LightGray;
            height: 50%;
        }
    }

    .openSource {
        @media only screen and (max-width: $iphone) {
            display: none;
        }
        position: absolute;
        right: 0.5vw;
        bottom: 0.5vw;
        color: $LightGray;
        // text-decoration: none;
        font-size: 0.5vw;
    }
    .privacyPolicyLink {
        @media only screen and (max-width: $iphone) {
            display: none;
        }
        position: absolute;
        left: 0.5vw;
        bottom: 0.5vw;
        color: $LightGray;
        // text-decoration: none;
        font-size: 0.5vw;
    }
}

.privacyPolicy {
    display: flex;
    justify-content: center;
    background-color: $SpotifyWhite;

    .markdown {
        color: $SpotifyBlack;
        background-color: $SpotifyWhite;
        width: 70vw;
        padding: 3vh 0 3vh 0;
        word-wrap: break-word;
        font-size: 16px;
	    line-height: 28px;
        list-style: inside;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

        p {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
        h1 {
            font-size: 40px;
            line-height: 60px;
            font-weight: 700;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
        }
        h2 {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
        }
        h3 {
            font-size: 24px;
            line-height: 36px;
            font-weight: 700;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
        h4 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
        }
        h5 {
            font-size: 16px
        }
        h5, h6 {
            line-height: 24px;
            font-weight: 700
        }
        h6 {
            font-size: 16px
        }
        ul {
            padding-left: 30px;
        }
        li {
            line-height: 40px;
        }
        a {
            // color: $SpotifyWhite;
            color: #1DB954;
        }
    }
}