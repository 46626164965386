@font-face {
  font-family: Gotham;
  src: url(./fonts/GothamMedium.otf);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background-color: #000000;
}

@media only screen and (max-width: 62em) {
  .headerShort {
    height: 100vh;
  }
}

.headerLong {
  height: 190vh;
}

@media only screen and (max-width: 62em) {
  .headerLong {
    height: 190vh;
  }
}

.headerMedium {
  height: 100vw;
}

@media only screen and (max-width: 62em) {
  .headerMedium {
    height: 175vh;
  }
}

.header {
  background-color: #000000;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.header * {
  font-family: Gotham;
}

@media only screen and (min-width: 62em) {
  .header {
    height: 100vh;
  }
}

.header .interspotLogo {
  width: 10vw;
}

@media only screen and (max-width: 62em) {
  .header .interspotLogo {
    width: 50vw;
  }
}

.header .welcomeContainer {
  width: 85vw;
  height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer {
    height: 145vh;
  }
}

.header .welcomeContainer .about {
  color: #FFFFFF;
  height: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
  width: 35%;
  font-size: 1.5vw;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .about {
    width: 90%;
    font-size: 6.5vw;
    height: 15%;
  }
}

.header .welcomeContainer .steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  height: 50%;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .steps {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 75%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.header .welcomeContainer .steps .result {
  width: 10vw;
}

.header .welcomeContainer .steps .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .steps .step {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 28%;
  }
}

.header .welcomeContainer .steps .step .number {
  width: 1.5vw;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .steps .step .number {
    display: none;
  }
}

.header .welcomeContainer .steps .step .screenshotWrapper .screenshot {
  height: 10vw;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .steps .step .screenshotWrapper .screenshot {
    height: 20vh;
    max-width: 80vw;
  }
}

.header .welcomeContainer .steps .step .stepInfo {
  color: #606060;
  height: 10%;
  text-align: center;
  font-size: 1.2vw;
  width: 15vw;
}

@media only screen and (max-width: 62em) {
  .header .welcomeContainer .steps .step .stepInfo {
    font-size: 6vw;
    width: 70vw;
  }
}

.header .containerHorizontal {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.header .containerVertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header .containerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 155vh;
}

.header .container {
  background-color: #121212;
  width: 85vw;
  height: 75vh;
  border-radius: 2vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 62em) {
  .header .container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 7vw;
  }
}

.header .container .verticalLine {
  border-left: 1px solid #202020;
  height: 95%;
}

.header .container .playlistSelection {
  height: 90%;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection {
    height: 90%;
  }
}

.header .container .signIn {
  height: 85%;
}

@media only screen and (max-width: 62em) {
  .header .container .signIn {
    height: 90%;
  }
}

.header .container .playlistSelection, .header .container .signIn {
  color: #FFFFFF;
  width: 32%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection, .header .container .signIn {
    width: 90%;
  }
}

.header .container .playlistSelection .name, .header .container .signIn .name {
  background-color: #202020;
  border-radius: 10vw;
  width: 30%;
  height: 6%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .name, .header .container .signIn .name {
    width: 60%;
    height: 8%;
  }
}

.header .container .playlistSelection .name input, .header .container .signIn .name input {
  background-color: #202020;
  max-width: 80%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  resize: none;
  border: none;
  color: #606060;
  font-size: 1.1vw;
  text-align: center;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .name input, .header .container .signIn .name input {
    font-size: 5vw;
  }
}

.header .container .playlistSelection .name input::-webkit-input-placeholder, .header .container .signIn .name input::-webkit-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .name input:-ms-input-placeholder, .header .container .signIn .name input:-ms-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .name input::-ms-input-placeholder, .header .container .signIn .name input::-ms-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .name input::placeholder, .header .container .signIn .name input::placeholder {
  color: #606060;
}

.header .container .playlistSelection .name input:focus, .header .container .signIn .name input:focus {
  outline: none;
}

.header .container .playlistSelection .profilePicture, .header .container .signIn .profilePicture {
  position: relative;
  width: 50%;
  border-radius: 100vw;
  border: none;
  background-color: #000000;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .profilePicture, .header .container .signIn .profilePicture {
    width: 60%;
  }
}

.header .container .playlistSelection .guide, .header .container .signIn .guide {
  color: #606060;
  font-size: 1.5vw;
  text-align: center;
  width: 70%;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .guide, .header .container .signIn .guide {
    font-size: 6vw;
    width: 80%;
  }
}

.header .container .playlistSelection .spotifyLogo, .header .container .signIn .spotifyLogo {
  width: 25%;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .spotifyLogo, .header .container .signIn .spotifyLogo {
    width: 30%;
  }
}

.header .container .playlistSelection .playlistsContainer, .header .container .signIn .playlistsContainer {
  background-color: #202020;
  width: 100%;
  height: 80%;
  border-radius: 2vw;
  padding: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .playlistsContainer, .header .container .signIn .playlistsContainer {
    border-radius: 4vw;
    height: 77%;
  }
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper, .header .container .signIn .playlistsContainer .playlistsWrapper {
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 37% 37%;
      grid-template-columns: 37% 37%;
  gap: 10%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection .playlistsContainer .playlistsWrapper, .header .container .signIn .playlistsContainer .playlistsWrapper {
    max-width: 100%;
    overflow-x: hidden;
    -ms-grid-columns: 40% 40%;
        grid-template-columns: 40% 40%;
  }
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistLink, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistLink {
  color: #606060;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistLink .playlistLinkIcon, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistLink .playlistLinkIcon {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.3rem;
  fill: #606060;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlist, .header .container .signIn .playlistsContainer .playlistsWrapper .playlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlist .playlistCover, .header .container .signIn .playlistsContainer .playlistsWrapper .playlist .playlistCover {
  width: 100%;
  margin-bottom: 10%;
  border-radius: 10%;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistSelected, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistSelected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
  background-color: #1ED760;
  padding: 5%;
  border-radius: 4%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistSelected .playlistCover, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistSelected .playlistCover {
  width: 100%;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistSelected .playlistName, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistSelected .playlistName {
  margin-top: 8%;
  margin-bottom: 5%;
}

.header .container .playlistSelection .playlistsContainer .playlistsWrapper .playlistSelected .playlistLink, .header .container .signIn .playlistsContainer .playlistsWrapper .playlistSelected .playlistLink {
  color: #FFFFFF;
}

.header .container .playlistSelection .addPlaylist, .header .container .playlistSelection .linkProfile, .header .container .signIn .addPlaylist, .header .container .signIn .linkProfile {
  width: 100%;
  background-color: #202020;
  height: 6%;
  border-radius: 2vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 3%;
}

.header .container .playlistSelection .addPlaylist input, .header .container .playlistSelection .linkProfile input, .header .container .signIn .addPlaylist input, .header .container .signIn .linkProfile input {
  background-color: #202020;
  width: 72%;
  resize: none;
  border: none;
  color: #606060;
  font-size: 1vw;
}

.header .container .playlistSelection .addPlaylist input::-webkit-input-placeholder, .header .container .playlistSelection .linkProfile input::-webkit-input-placeholder, .header .container .signIn .addPlaylist input::-webkit-input-placeholder, .header .container .signIn .linkProfile input::-webkit-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .addPlaylist input:-ms-input-placeholder, .header .container .playlistSelection .linkProfile input:-ms-input-placeholder, .header .container .signIn .addPlaylist input:-ms-input-placeholder, .header .container .signIn .linkProfile input:-ms-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .addPlaylist input::-ms-input-placeholder, .header .container .playlistSelection .linkProfile input::-ms-input-placeholder, .header .container .signIn .addPlaylist input::-ms-input-placeholder, .header .container .signIn .linkProfile input::-ms-input-placeholder {
  color: #606060;
}

.header .container .playlistSelection .addPlaylist input::placeholder, .header .container .playlistSelection .linkProfile input::placeholder, .header .container .signIn .addPlaylist input::placeholder, .header .container .signIn .linkProfile input::placeholder {
  color: #606060;
}

.header .container .playlistSelection .addPlaylist input:focus, .header .container .playlistSelection .linkProfile input:focus, .header .container .signIn .addPlaylist input:focus, .header .container .signIn .linkProfile input:focus {
  outline: none;
}

.header .container .playlistSelection .addPlaylist img, .header .container .playlistSelection .linkProfile img, .header .container .signIn .addPlaylist img, .header .container .signIn .linkProfile img {
  height: 50%;
  fill: #606060;
}

.header .container .playlistSelection .addPlaylist button, .header .container .playlistSelection .linkProfile button, .header .container .signIn .addPlaylist button, .header .container .signIn .linkProfile button {
  background-color: #1ED760;
  color: #FFFFFF;
  height: 100%;
  border: none;
  border-radius: 2vw;
  width: 20%;
  font-size: 1.1vw;
  cursor: pointer;
}

.header .container .playlistSelection button, .header .container .signIn button {
  background-color: #1ED760;
  color: #FFFFFF;
  height: 6%;
  border: none;
  border-radius: 10vw;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 1.1vw;
  cursor: pointer;
}

.header .container .playlistSelection button:disabled, .header .container .signIn button:disabled {
  background-color: #606060;
  color: #202020;
  cursor: not-allowed;
}

@media only screen and (max-width: 62em) {
  .header .container .playlistSelection button, .header .container .signIn button {
    width: 50%;
    height: 8%;
    font-size: 5vw;
  }
}

.header .container .loading {
  color: #FFFFFF;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.1vw;
  height: 25%;
}

@media only screen and (max-width: 62em) {
  .header .container .loading {
    font-size: 5vw;
    height: 30%;
  }
}

.header .container .loading .loadingAnimation {
  width: 7vw;
  height: 7vw;
  position: relative;
}

@media only screen and (max-width: 62em) {
  .header .container .loading .loadingAnimation {
    width: 25vw;
    height: 25vw;
  }
}

.header .container .loading .double-bounce1, .header .container .loading .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #606060;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.header .container .loading .double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.header .container .intersectionCard {
  background-color: #202020;
  border-radius: 2vw;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30%;
  height: 90%;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard {
    width: 90%;
    height: 94%;
    border-radius: 7vw;
  }
}

.header .container .intersectionCard .intersectionName {
  font-size: 1.5vw;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .intersectionName {
    font-size: 6vw;
  }
}

.header .container .intersectionCard .intersectionCover {
  width: 80%;
  border-radius: 1vw;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .intersectionCover {
    border-radius: 5vw;
    width: 85%;
  }
}

.header .container .intersectionCard .intersectionLink {
  width: 80%;
  background-color: #606060;
  height: 6%;
  border-radius: 10vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 3%;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .intersectionLink {
    display: none;
  }
}

.header .container .intersectionCard .intersectionLink a {
  text-decoration: none;
  font-size: 0.53vw;
  color: #FFFFFF;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .intersectionLink a {
    font-size: 1.8vw;
  }
}

.header .container .intersectionCard .intersectionLink button {
  background-color: #606060;
  color: #FFFFFF;
  height: 100%;
  border: none;
  border-radius: 2vw;
  width: 15%;
  font-size: 0.7vw;
  cursor: pointer;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .intersectionLink button {
    display: none;
  }
}

.header .container .intersectionCard .intersectionLink button .copyIcon {
  height: 1.5vh;
  aspect-ratio: 1;
}

.header .container .intersectionCard .openInSpotify {
  width: 80%;
  background-color: #1ED760;
  border-radius: 10vw;
  height: 7%;
  color: #FFFFFF;
  font-size: 1vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .openInSpotify {
    font-size: 4vw;
    height: 9%;
  }
}

.header .container .intersectionCard .playListIsInYourLibrary {
  font-size: 0.8vw;
}

@media only screen and (max-width: 62em) {
  .header .container .intersectionCard .playListIsInYourLibrary {
    font-size: 4vw;
    width: 70%;
  }
}

.header .container .error {
  color: #FFFFFF;
  font-size: 1vw;
}

@media only screen and (max-width: 62em) {
  .header .container .error {
    width: 80%;
    font-size: 6vw;
    text-align: center;
  }
}

.header .mainButton {
  background-color: #1ED760;
  color: #FFFFFF;
  width: 12vw;
  height: 3vw;
  border-radius: 10vw;
  border: none;
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 62em) {
  .header .mainButton {
    width: 55vw;
    height: 15vw;
    font-size: 7vw;
  }
}

.header .mainButton:disabled {
  background-color: #606060;
  color: #202020;
  cursor: not-allowed;
}

.header .startOnGitHub {
  position: absolute;
  right: 0.5vw;
  bottom: 0.5vw;
  color: #606060;
  text-decoration: none;
  font-size: 0.8vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  justify-items: center;
  background-color: #202020;
  height: 2vw;
  width: 8.5vw;
  border-radius: 10vw;
}

@media only screen and (max-width: 62em) {
  .header .startOnGitHub {
    display: none;
  }
}

.header .startOnGitHub svg {
  fill: #606060;
  height: 50%;
}

.header .openSource {
  position: absolute;
  right: 0.5vw;
  bottom: 0.5vw;
  color: #606060;
  font-size: 0.5vw;
}

@media only screen and (max-width: 62em) {
  .header .openSource {
    display: none;
  }
}

.header .privacyPolicyLink {
  position: absolute;
  left: 0.5vw;
  bottom: 0.5vw;
  color: #606060;
  font-size: 0.5vw;
}

@media only screen and (max-width: 62em) {
  .header .privacyPolicyLink {
    display: none;
  }
}

.privacyPolicy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #FFFFFF;
}

.privacyPolicy .markdown {
  color: #000000;
  background-color: #FFFFFF;
  width: 70vw;
  padding: 3vh 0 3vh 0;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 28px;
  list-style: inside;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.privacyPolicy .markdown p {
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}

.privacyPolicy .markdown h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
}

.privacyPolicy .markdown h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  -webkit-margin-before: 0.83em;
          margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
          margin-block-end: 0.83em;
}

.privacyPolicy .markdown h3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}

.privacyPolicy .markdown h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.privacyPolicy .markdown h5 {
  font-size: 16px;
}

.privacyPolicy .markdown h5, .privacyPolicy .markdown h6 {
  line-height: 24px;
  font-weight: 700;
}

.privacyPolicy .markdown h6 {
  font-size: 16px;
}

.privacyPolicy .markdown ul {
  padding-left: 30px;
}

.privacyPolicy .markdown li {
  line-height: 40px;
}

.privacyPolicy .markdown a {
  color: #1DB954;
}
